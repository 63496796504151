import React, { lazy, Suspense } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";

import Footer from "./compenents/Footer/Footer";
import MixblendCursor from "./compenents/MixblendCursor/MixblendCursor";
import LodingPart from "./compenents/LodingPart/LodingPart";

const Home = lazy(() => import("./pages/Home/Home"));
const ServicePage = lazy(() => import("./pages/ServicePage/ServicePage"));
const Careers = lazy(() => import("./pages/CareersPage/Careers"));
const AboutPage = lazy(() => import("./pages/AboutPage/AboutPage"));
const Contact = lazy(() => import("./pages/Contact/Contact"));
const BlogPage = lazy(() => import("./pages/BlogPage/BlogPage"));
const BrandPage = lazy(() => import("./pages/BrandPage/BrandPage"));
const Portfolio = lazy(() => import("./pages/Portfolio/Portfolio"));
const NewPortfolio = lazy(() => import("./pages/NewPortfolio/NewPortfolio"));
const NewPage = lazy(() => import("./compenents/NewPage/NewPage"));
const TechService = lazy(() => import("./pages/TechService/TechService"));
const MediaService = lazy(() => import("./pages/MediaService/MediaService"));
const MarketingService = lazy(() =>
  import("./pages/MarketingService/MarketingService")
);
const StrategyService = lazy(() =>
  import("./pages/StrategyService/StrategyService")
);
const SingleJobDetails = lazy(() =>
  import("./compenents/SingleJobDetails/SingleJobDetails")
);
const SingleBlogDetails = lazy(() =>
  import("./compenents/SingleBlogDetails/SingleBlogDetails")
);
//done
const TermsAndConditions = lazy(() => import("./compenents/Term&Cond/terms"));

const PrivacyPolicy = lazy(() =>
  import("./compenents/privacyData/PrivacyPolicy")
);

function App() {
  return (
    <div className="app">
      <Router>
        <MixblendCursor />
        <Suspense fallback={<LodingPart />}>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/service" element={<ServicePage />} />
            <Route path="/career" element={<Careers />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/brand" element={<BrandPage />} />
            <Route path="/Portfolio" element={<Portfolio />} />
            <Route path="/NewPortfolio" element={<NewPortfolio />} />
            <Route path="/NewPoftFolioPage" element={<NewPage />} />
            <Route path="/techservice" element={<TechService />} />
            <Route path="/MediaService" element={<MediaService />} />
            <Route path="/marketing-service" element={<MarketingService />} />
            <Route path="/strategy-service" element={<StrategyService />} />
            <Route path="/SingleJobPost/:id" element={<SingleJobDetails />} />
            <Route path="/blog/:id" element={<SingleBlogDetails />} />
            <Route
              path="/TermsAndConditions"
              element={<TermsAndConditions />}
            />
            <Route path="/PrivacyPolicy" element={<PrivacyPolicy />} />
          </Routes>

          <Footer />
        </Suspense>
      </Router>
    </div>
  );
}

export default App;
