import React from "react";
import "./LodingPart.scss";

const dimensions = [1, 2, 3];

const Cube = ({ h, w, l }) => (
  <div className={`cube h${h} w${w} l${l}`}>
    <div className="face top"></div>
    <div className="face left"></div>
    <div className="face right"></div>
  </div>
);

const LodingPart = () => {
  return (
    <div className="containerParetONLoading">
      {dimensions.map((h) => (
        <div key={h} className={`h${h}Container  kjdhfkjdhkjfh`}>
          {dimensions.map((w) =>
            dimensions.map((l) => (
              <Cube key={`${h}-${w}-${l}`} h={h} w={w} l={l} />
            ))
          )}
        </div>
      ))}
    </div>
  );
};

export default LodingPart;
